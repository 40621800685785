import React, { useEffect, useState } from 'react';
import Logo from '../../components/atoms/Logo/Logo';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Button from '../../components/atoms/CustomButton/CustomButton';

import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ArrowOutward from '@mui/icons-material/ArrowOutward';
import Close from '@mui/icons-material/Close';

import styles from '../../components/organisms/OldHeader/OldHeader.module.scss';
import Row from '@atoms/CustomGrid/Row';
import GridContainer from '@atoms/GridContainer/GridContainer';
import { absoluteUrl } from '@utils/functions/utils';

function MenuItem({ el }) {
  const { t } = useTranslation();
  return (
    <ListItem disablePadding style={{ position: 'static' }} className={styles.dropdownMenuItem}>
      <Link prefetch={false} href={el.url}>
        <ListItemButton style={{ paddingLeft: 0 }}>
          <ListItemText primary={el.title} />
          {el?.items?.length > 0 && (
            <ListItemIcon>
              <ArrowForwardIos fontSize="small" color="primary" />
            </ListItemIcon>
          )}
        </ListItemButton>
      </Link>
      {el?.items?.length > 0 && (
        <div className={styles.dropdownSubMenu}>
          <Typography variant="bodyS" component="div" className={styles.dropdownTitle}>
            {t('header.menu.whatWeOffer')}
          </Typography>
          <List disablePadding>
            {el.items.map((submenu, i) => {
              return <MenuItem key={i} el={submenu} />;
            })}
          </List>
        </div>
      )}
    </ListItem>
  );
}

function MenuItemExpanded({ items }) {
  const generateCols = () => {
    let menu = [[], [], []];
    let col = 0;

    for (let i = 0; i < items.length; i++) {
      if (items[i].expanded) col++;
      menu[col].push(items[i]);
    }
    return menu;
  };

  return (
    <>
      {generateCols().map((col, index) => {
        return (
          <Grid
            key={`Col_${index}`}
            item
            lg={4}
            position="relative"
            borderRight={index < 2 ? '1px solid #DFE1E6' : null}
          >
            <List disablePadding>
              {col.length > 0 &&
                col.map((el, i) => {
                  return (
                    <React.Fragment key={`ListItem_${index}_${i}`}>
                      {el.description != null && (
                        <Typography
                          variant="bodyL"
                          component="div"
                          className={styles.dropdownTitle}
                          dangerouslySetInnerHTML={{ __html: el.description }}
                        />
                      )}
                      <ListItem disablePadding style={{ position: 'static' }}>
                        <Link prefetch={false} href={el.url}>
                          <ListItemButton style={{ paddingLeft: 0 }}>
                            <ListItemText primary={el.title} />
                          </ListItemButton>
                        </Link>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
            </List>
          </Grid>
        );
      })}
    </>
  );
}

function MenuElement({ item }) {
  const { t } = useTranslation();
  const router = useRouter();

  const active = router?.query?.slug && item.url.includes(`/${router.locale}/${router.query.slug[0]}`) ? true : false;
  const aziende = item.title == t('companies') ? true : false;
  const [dropdown, setDropdown] = useState(item?.items?.length > 0);

  useEffect(() => {
    setDropdown(false);

    setTimeout(() => {
      setDropdown(item?.items?.length > 0);
    }, 1000);
  }, [router.asPath, item?.items?.length]);

  const blockScroll = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = '4px';
  };
  const scroll = () => {
    document.body.style.overflow = 'auto';
    document.body.style.paddingRight = '0';
  };

  return (
    <div
      className={`${styles.menuItem} ${dropdown ? styles.dropdown : ''}`}
      onMouseEnter={() => {
        blockScroll();
      }}
      onMouseLeave={() => {
        scroll();
      }}
    >
      <Button
        href={item.url}
        text={item.title}
        className={`${styles.item} ${active ? styles.itemActive : ''}`}
        variant="link"
        color="link"
      />
      {item?.items?.length > 0 && (
        <div className={styles.dropdownMenu}>
          <GridContainer>
            <Row spacing={0} justifyContent={'space-between'}>
              {item.expanded ? (
                <MenuItemExpanded items={item.items} />
              ) : (
                <>
                  <Grid item lg={4} position="relative" borderRight={'1px solid #DFE1E6'}>
                    <List disablePadding>
                      {item.items.map((el, i) => (
                        <MenuItem el={el} key={i} />
                      ))}
                    </List>
                  </Grid>
                  <Grid item lg={4}>
                    <div className={styles.case}>
                      <div className={styles.caseImage}>
                        <Image
                          src={absoluteUrl(
                            `/sites/default/files/2022-09/${aziende ? 'michelin-italia.png ' : 'EDN-img-half-21.jpg'}`
                          )}
                          alt={
                            aziende
                              ? t('header.menu.caseHistory.michelin.title')
                              : t('header.menu.caseHistory.cremona.title')
                          }
                          layout="fill"
                          objectFit="cover"
                          quality={100}
                        />
                      </div>
                      <Typography variant="overline" component="div" mt={24}>
                        {t('header.menu.caseHistory.label')}
                      </Typography>
                      <Typography variant="h4" textTransform="initial" mt={10}>
                        {aziende
                          ? t('header.menu.caseHistory.michelin.title')
                          : t('header.menu.caseHistory.cremona.title')}
                      </Typography>
                      <Button
                        variant="large"
                        color="secondary"
                        href={
                          aziende
                            ? t('header.menu.caseHistory.michelin.ctaUrl')
                            : t('header.menu.caseHistory.cremona.ctaUrl')
                        }
                        text={t('discoverMore')}
                        sx={{ marginTop: 34 }}
                      />
                    </div>
                  </Grid>
                </>
              )}
            </Row>
          </GridContainer>
        </div>
      )}
    </div>
  );
}

function MenuMobileElement({ item, close, parentOpen }) {
  const { t } = useTranslation();
  const hasChild = item?.items?.length > 0 ? true : false;
  const [open, setOpen] = useState(false);

  const goNext = () => {
    setOpen(true);
  };

  const goBack = () => {
    setOpen(false);
  };

  const closeMenu = () => {
    goBack();
    close();
  };

  return (
    <>
      {hasChild ? (
        <ListItem disablePadding>
          <ListItemButton sx={{ px: 0 }} component="div" onClick={goNext}>
            <ListItemText primary={item.title} />
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
              <ArrowForwardIos fontSize="small" color="primary" />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      ) : (
        <>
          {item.description != null && (
            <Typography
              variant="bodyL"
              component="div"
              className={styles.dropdownTitle}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
          <ListItem disablePadding>
            <Link prefetch={false} href={item.url}>
              <ListItemButton sx={{ px: 0 }}>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </Link>
          </ListItem>
        </>
      )}

      {hasChild && parentOpen && (
        <Drawer
          open={open}
          ModalProps={{ keepMounted: false }}
          SlideProps={{ direction: 'left' }}
          onClose={closeMenu}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          <Box>
            <Container maxWidth={false} className={styles.header_drawer}>
              <Box className={styles.logo} display="flex" alignItems="center" justifyContent="space-between">
                <Link prefetch={false} href="/">
                  <Logo />
                </Link>
                <IconButton color="inherit" aria-label="close drawer" edge="end" onClick={closeMenu}>
                  <Close />
                </IconButton>
              </Box>

              <Box sx={{ mt: 24 }} className={styles.mainMenuPrimary}>
                <ListItem disablePadding>
                  <ListItemButton sx={{ p: 0 }} component="a" onClick={goBack}>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <ArrowBackIos fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={item.title} primaryTypographyProps={{ fontWeight: 500 }} />
                  </ListItemButton>
                </ListItem>

                <Link prefetch={false} href={item.url}>
                  <Typography variant="bodyL" color="primary" fontWeight={500} ml={32} mb={4} display="block">
                    {t('discoverMore')}
                  </Typography>
                </Link>

                <List>
                  {item?.items
                    ? item?.items.map((el, i) => (
                        <MenuMobileElement key={i} item={el} close={close} parentOpen={parentOpen} />
                      ))
                    : null}
                </List>
              </Box>

              <Button
                className={styles.buttonDrawer}
                id="header-mobile-contact-us"
                variant="large"
                text={t('header.button')}
                href={t('contact_us_url')}
              />
            </Container>
          </Box>
        </Drawer>
      )}
    </>
  );
}

export const renderMainMenu = (menu) => menu?.map((item, index) => <MenuElement key={index} item={item} />);

export const renderMainMenuMobile = (menu, close, parentOpen) =>
  menu?.map((item, index) => <MenuMobileElement key={index} item={item} close={close} parentOpen={parentOpen} />);

export const renderFooterMenu = (menu) => {
  return menu.map((item, index) => {
    let external = item.options.external && !item.url.includes(process.env.NEXT_PUBLIC_BASE_URL);
    // test
    return (
      <Typography
        key={index}
        variant="bodyM"
        component="a"
        href={item.url}
        target={external ? '_blank' : '_self'}
        mb={4}
      >
        {item.title} {external && <ArrowOutward />}
      </Typography>
    );
  });
};

export const renderCopyrightMenu = (menu) => {
  return menu?.map((item, index) => (
    <Link prefetch={false} key={index} href={item.url}>
      {item.title}
    </Link>
  ));
};

export const renderFooterBlock = (menu) => {
  return menu?.map((item, index) => (
    <Typography
      key={index}
      variant="bodyS"
      component="div"
      fontWeight={300}
      mb={16}
      dangerouslySetInnerHTML={{
        __html: item.body?.processed.replace(
          '/sites/default/files/',
          `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/sites/default/files/`
        ),
      }}
    />
  ));
};
