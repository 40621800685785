import Link from 'next/link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
// import LinkedIn from '@mui/icons-material/LinkedIn';
import Logo from '../../atoms/Logo/Logo';
import { renderCopyrightMenu, renderFooterBlock, renderFooterMenu } from '../../../utils/functions/menu';
import styles from './Footer.module.scss';
import { useRouter } from 'next/router';

import { useTranslation } from 'next-i18next';
import GridContainer from '@atoms/GridContainer/GridContainer';
import Row from '@atoms/CustomGrid/Row';
import Button from '@atoms/CustomButton/CustomButton';
import FacebookVector from '@atoms/CustomVectors/FacebookVector';
import InstagramVector from '@atoms/CustomVectors/InstagramVector';
import LinkedInVector from '@atoms/CustomVectors/LinkedinVector';
import TwitterVector from '@atoms/CustomVectors/TwitterVector';
import YoutubeVector from '@atoms/CustomVectors/YoutubeVector';

const vectorMap = {
  facebook: <FacebookVector />,
  instagram: <InstagramVector />,
  linkedin: <LinkedInVector />,
  twitter: <TwitterVector />,
  youtube: <YoutubeVector />,
};

export default function Footer({ menus }) {
  // const {
  //   mappedData: { data, isLoaded },
  // } = useMapper<any, any>({
  //   backendData: menus,
  //   mockDataUrl: '/data/footer.json',
  //   realDataMapper: (setData, backendData) => {
  //     return setData({
  //       blockFooterBottom: backendData?.blockFooterBottom,
  //       blockFooterMiddle: backendData.blockFooterMiddle,
  //       blockFooterTop: backendData.blockFooterTop,
  //       copyrightMenu: backendData.copyrightMenu,
  //       footerMenuColOne: backendData.footerMenuColOne,
  //       footerMenuColTwo: backendData.footerMenuColTwo,
  //       socialsMenu: backendData.socialsMenu,
  //     });
  //   },
  // });

  const data = useMemo(() => {
    const newData: any = {
      blockFooterBottom: menus?.blockFooterBottom,
      blockFooterMiddle: menus.blockFooterMiddle,
      blockFooterTop: menus.blockFooterTop,
      copyrightMenu: menus.copyrightMenu,
      footerMenuColOne: menus.footerMenuColOne,
      footerMenuColTwo: menus.footerMenuColTwo,
      socialsMenu: menus.socialsMenu,
    };

    return newData;
  }, [menus]);

  const { t } = useTranslation();
  const router = useRouter();
  const firstFiveItemsColOne = data?.footerMenuColOne.slice(0, 5);
  const restOfTheItemsColOne = data?.footerMenuColOne.slice(5);

  data?.socialsMenu &&
    data?.socialsMenu
      .filter((el) => !!el.title)
      .map((el) => {
        el.component = () => vectorMap[el.title.toLowerCase()];
      });

  if (!data) return null;

  return (
    <footer className={styles.footer}>
      <GridContainer maxWidth={false} className={styles.footerContent}>
        <Row>
          <Grid item xs={12} mb={24} marginBottom="3rem">
            <Link prefetch={false} href="/" aria-label="Logo webuildgroup">
              <Logo />
            </Link>
          </Grid>

          <Grid item xs={12} md={5} className={`${styles.footerMenu} ${styles.blockFooterTop}`}>
            {data?.blockFooterTop && renderFooterBlock(data?.blockFooterTop)}
          </Grid>

          <Grid item md={1} />

          <Grid
            sx={{ display: { xs: 'none', md: 'flex' } }}
            item
            md={3}
            className={`${styles.footerMenu} ${styles.footerMenuColOne}`}
          >
            {data?.footerMenuColOne && renderFooterMenu(data?.footerMenuColOne)}
          </Grid>
          <Grid sx={{ display: { xs: 'flex', md: 'none' } }} item xs={12}>
            <Grid item xs={6} className={`${styles.footerMenu} ${styles.footerMenuColOne}`}>
              {firstFiveItemsColOne && renderFooterMenu(firstFiveItemsColOne)}
            </Grid>
            <Grid item xs={6} className={`${styles.footerMenu} ${styles.footerMenuColOne}`}>
              {restOfTheItemsColOne && renderFooterMenu(restOfTheItemsColOne)}
            </Grid>
          </Grid>

          {!!data?.footerMenuColTwo.length && (
            <Grid item xs={12} md={3} className={`${styles.footerMenu} ${styles.footerMenuColTwo}`}>
              {data?.footerMenuColTwo && renderFooterMenu(data?.footerMenuColTwo)}
            </Grid>
          )}

          {data?.blockFooterMiddle[0] && (
            <Grid item xs={12} md={3} className={`${styles.blockFooterMiddle}`}>
              {/* {router.locale == "it" ? renderFooterMenu(footerMenuColThree) : renderFooterMenu(footerMenuColThreeEng)} */}
              {/* {blockFooterTop && renderFooterBlock(blockFooterMiddle)} */}
              <Typography
                variant="bodyS"
                component="div"
                fontWeight={300}
                mb={16}
                dangerouslySetInnerHTML={{
                  __html: data?.blockFooterMiddle[0].body?.processed,
                }}
              />
            </Grid>
          )}
          {data?.blockFooterMiddle[1] && (
            <Grid item xs={12} md={3} className={`${styles.blockFooterMiddle}`}>
              <Typography
                variant="bodyS"
                component="div"
                fontWeight={300}
                mb={16}
                dangerouslySetInnerHTML={{
                  __html: data?.blockFooterMiddle[1].body?.processed,
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={3} className={`${styles.blockFooterMiddle}`}>
            <div className={styles.blockFooterMiddleCol}>
              {data?.blockFooterMiddle[2] && (
                <Typography
                  variant="bodyS"
                  component="div"
                  fontWeight={300}
                  mb={16}
                  dangerouslySetInnerHTML={{
                    __html: data?.blockFooterMiddle[2].body?.processed,
                  }}
                />
              )}
              {data?.blockFooterMiddle[3] && (
                <Typography
                  variant="bodyS"
                  component="div"
                  fontWeight={300}
                  mt={16}
                  dangerouslySetInnerHTML={{
                    __html: data?.blockFooterMiddle[3].body?.processed,
                  }}
                />
              )}
              {data?.socialsMenu && (
                <div className={styles.socialsMenu}>
                  {data?.socialsMenu.map(({ title, url, component: Component }) => (
                    <Link prefetch={false} key={title} href={url} passHref target="_blank">
                      <Component />
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </Grid>
          {data?.blockFooterMiddle[4] && (
            <Grid item xs={12} md={3} className={`${styles.blockFooterMiddle} ${styles.blockFooterMiddleCta}`}>
              <Typography
                variant="bodyS"
                component="div"
                fontWeight={300}
                mb={16}
                dangerouslySetInnerHTML={{
                  __html: data?.blockFooterMiddle[4].body?.processed,
                }}
              />
              <div>
                <Button
                  href={`/${router.locale}/newsletter`}
                  color="secondaryOnDark"
                  variant="large"
                  text={t('footer.subscribe')}
                />
              </div>
            </Grid>
          )}
        </Row>
      </GridContainer>

      <GridContainer className={styles.copyright}>
        <hr className={styles.copyrightLine} />
        <Row className={styles.copyrightRow} flexDirection={{ md: 'row-reverse' }}>
          <Grid item md={5} className={styles.copyrightMenu}>
            {data?.copyrightMenu && renderCopyrightMenu(data?.copyrightMenu)}
          </Grid>

          <Grid item md={7}>
            <div className={styles.copyrightString}>
              {data?.blockFooterBottom && renderFooterBlock(data?.blockFooterBottom)}
            </div>
          </Grid>
        </Row>
      </GridContainer>
    </footer>
  );
}
