import Grid, { GridProps } from '@mui/material/Grid';
import { forwardRef } from 'react';

const Row = forwardRef<HTMLDivElement, GridProps>(function Row(props, ref) {
  return (
    <Grid {...props} ref={ref} container columns={props.columns || 12} columnSpacing={{ xs: 8, md: 16, xl: 24 }} />
  );
});

export default Row;
