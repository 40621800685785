const InstagramVector = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5ZM4.25 8C4.25 5.92893 5.92893 4.25 8 4.25C10.0711 4.25 11.75 5.92893 11.75 8C11.75 10.0711 10.0711 11.75 8 11.75C5.92893 11.75 4.25 10.0711 4.25 8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5625 1.4375C2.83661 1.4375 1.4375 2.83661 1.4375 4.5625V11.4375C1.4375 13.1634 2.83661 14.5625 4.5625 14.5625H11.4375C13.1634 14.5625 14.5625 13.1634 14.5625 11.4375V4.5625C14.5625 2.83661 13.1634 1.4375 11.4375 1.4375H4.5625ZM0.1875 4.5625C0.1875 2.14625 2.14625 0.1875 4.5625 0.1875H11.4375C13.8537 0.1875 15.8125 2.14625 15.8125 4.5625V11.4375C15.8125 13.8537 13.8537 15.8125 11.4375 15.8125H4.5625C2.14625 15.8125 0.1875 13.8537 0.1875 11.4375V4.5625Z"
        fill="white"
      />
      <path
        d="M12.0625 4.875C12.5803 4.875 13 4.45527 13 3.9375C13 3.41973 12.5803 3 12.0625 3C11.5447 3 11.125 3.41973 11.125 3.9375C11.125 4.45527 11.5447 4.875 12.0625 4.875Z"
        fill="white"
      />
    </svg>
  );
};

export default InstagramVector;
