import Box from '@mui/material/Box';
import { PropsWithSx } from '@model/types';

const FacebookVector = ({ sxOverride }: PropsWithSx) => {
  return (
    <Box
      component="svg"
      sx={sxOverride}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.875C4.51875 0.875 0.875 4.51875 0.875 9C0.875 13.4812 4.51875 17.125 9 17.125C13.4812 17.125 17.125 13.4812 17.125 9C17.125 4.51875 13.4812 0.875 9 0.875ZM9.625 15.8438V10.875H11.5C11.8438 10.875 12.125 10.5938 12.125 10.25C12.125 9.90625 11.8438 9.625 11.5 9.625H9.625V7.7375C9.625 7.40625 9.75625 7.09375 9.99375 6.8625C10.225 6.63125 10.5375 6.5 10.8687 6.5H12.125C12.4688 6.5 12.75 6.21875 12.75 5.875C12.75 5.53125 12.4688 5.25 12.125 5.25H10.8687C10.2063 5.25 9.58125 5.50625 9.1125 5.975C8.6375 6.44375 8.375 7.06875 8.375 7.73125V9.625H6.5C6.15625 9.625 5.875 9.90625 5.875 10.25C5.875 10.5938 6.15625 10.875 6.5 10.875H8.375V15.8438C4.875 15.525 2.125 12.575 2.125 9C2.125 5.20625 5.20625 2.125 9 2.125C12.7937 2.125 15.875 5.20625 15.875 9C15.875 12.5813 13.125 15.5312 9.625 15.8438Z"
        fill="currentColor"
      />
    </Box>
  );
};

export default FacebookVector;
