import Box from '@mui/material/Box';
import { PropsWithSx } from '@model/types';

const LinkedInVector = ({ sxOverride }: PropsWithSx) => {
  return (
    <Box
      component="svg"
      sx={sxOverride}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.1875 1.4375C0.1875 0.747144 0.747144 0.1875 1.4375 0.1875H14.5625C15.2529 0.1875 15.8125 0.747144 15.8125 1.4375V14.5625C15.8125 15.2529 15.2529 15.8125 14.5625 15.8125H1.4375C0.747144 15.8125 0.1875 15.2529 0.1875 14.5625V1.4375ZM14.5625 1.4375H1.4375V14.5625H14.5625V1.4375Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.375 6.125C7.72018 6.125 8 6.40482 8 6.75V11.75C8 12.0952 7.72018 12.375 7.375 12.375C7.02982 12.375 6.75 12.0952 6.75 11.75V6.75C6.75 6.40482 7.02982 6.125 7.375 6.125Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.875 6.125C5.22018 6.125 5.5 6.40482 5.5 6.75V11.75C5.5 12.0952 5.22018 12.375 4.875 12.375C4.52982 12.375 4.25 12.0952 4.25 11.75V6.75C4.25 6.40482 4.52982 6.125 4.875 6.125Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5625 7.375C9.1481 7.375 8.75067 7.53962 8.45765 7.83265C8.16462 8.12567 8 8.5231 8 8.9375C8 9.28268 7.72018 9.5625 7.375 9.5625C7.02982 9.5625 6.75 9.28268 6.75 8.9375C6.75 8.19158 7.04632 7.47621 7.57376 6.94876C8.10121 6.42132 8.81658 6.125 9.5625 6.125C10.3084 6.125 11.0238 6.42132 11.5512 6.94876C12.0787 7.47621 12.375 8.19158 12.375 8.9375V11.75C12.375 12.0952 12.0952 12.375 11.75 12.375C11.4048 12.375 11.125 12.0952 11.125 11.75V8.9375C11.125 8.5231 10.9604 8.12567 10.6674 7.83265C10.3743 7.53962 9.9769 7.375 9.5625 7.375Z"
        fill="currentColor"
      />
      <path
        d="M4.875 5.1875C5.39277 5.1875 5.8125 4.76777 5.8125 4.25C5.8125 3.73223 5.39277 3.3125 4.875 3.3125C4.35723 3.3125 3.9375 3.73223 3.9375 4.25C3.9375 4.76777 4.35723 5.1875 4.875 5.1875Z"
        fill="currentColor"
      />
    </Box>
  );
};

export default LinkedInVector;
